import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  titleBorder: {
    borderBottom: theme.custom.largeBorder,
    paddingTop: 8,
  },
}));

export default function UnderlinedText({ children }) {
  const classes = useStyles();
  return (
      <Typography style={{ display: 'inline-block', color: 'black', textTransform: 'uppercase' }} variant="h3">
        {children}
        <div className={classes.titleBorder} />
      </Typography>
  );
}
import React from 'react';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import ResponsiveComponentRenderer from '../stuff/ResponsiveComponentRenderer';

export default function Navbar({currentPath, animated}) {  
  
  return (
    <ResponsiveComponentRenderer
      size="lg"
      mobileComponent={<MobileNavbar />}
      desktopComponent={<DesktopNavbar currentPath={currentPath} animated={animated} />}
    />)
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FooterList from './FooterList';
import services from '../navbar/services';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },
}));

export default function FooterServices() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FooterList listItems={services} padding={6} />
    </div>
  );
}

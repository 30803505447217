import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

const useStyles = makeStyles(theme => ({
  containerWithBottomBorder: {
    width: props => props.width,
    paddingLeft: props => props.left,
    paddingBottom: props => props.bottom,
    marginRight: props => props.marginRight,
    flexGrow: props => props.flexGrow,
    // borderBottom: theme.custom.standardBorder,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  blackBorder: {
    marginTop: 9,
    width: '100%',
    height: 3,
    backgroundColor: 'black',
    position: 'relative',
    pointerEvents: 'none',
  },
  yellowBorder: {
    position: 'absolute',
    width: '100%',
    height: 10,
    backgroundColor: theme.palette.primaryYellow.main,
    border: '3px solid black'
  }
}));

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30
};

export default function DesktopNavContainer({ children, paddingBottom, paddingLeft, fixedWith, lastItem, isSelected}) {
  const props = {
    left: paddingLeft,
    bottom: paddingBottom,
    width: fixedWith? 215 : '',
    flexGrow: fixedWith? 0 : 1,
    marginRight: lastItem? 0 : 24,
  };
  const classes = useStyles(props);
  return (
    <div className={classes.containerWithBottomBorder}>
      {children}
      <div className={classes.blackBorder}>
        {isSelected &&
          <motion.div
            className={classes.yellowBorder}
            layoutId="selectedMenuItem"
            transition={spring}
          />
        }
      </div>
    </div>
  );
}



/**
* Type definitions
*/
DesktopNavContainer.propTypes = {
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  fixedWith: PropTypes.bool,
  lastItem: PropTypes.bool,
};

DesktopNavContainer.defaultProps = {
  paddingBottom: 9,
  paddingLeft: 0,
  fixedWith: true,
  lastItem: false,
};

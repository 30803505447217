const services = [
  {
    label: 'hemsidor',
    to: '/tjanster/hemsidor/',
    showInNavbar: true
  },
  {
    label: 'startup-paketet',
    to: '/tjanster/startup-paketet/',
    showInNavbar: true
  },
  {
    label: 'firma-paketet',
    to: '/tjanster/firma-paketet/',
    showInNavbar: true
  },
  {
    label: 'jamstack-paketet',
    to: '/tjanster/jamstack-paketet/',
    showInNavbar: true
  },
  {
    label: 'SEO-tjänster',
    to: '/tjanster/seo/',
    showInNavbar: true
  },
  {
    label: 'E-Handel',
    to: '/tjanster/e-handel/',
    showInNavbar: true
  },
  {
    label: 'Våra tillval',
    to: '/tjanster/tillval/',
    showInNavbar: false
  },
  {
    label: 'Grafisk profil',
    to: '/tjanster/grafisk-profil/',
    showInNavbar: false
  },
  {
    label: 'Designsystem',
    to: '/tjanster/designsystem/',
    showInNavbar: false
  },
  {
    label: 'Förvaltning',
    to: '/tjanster/forvaltning/',
    showInNavbar: false
  },
];

export const navbarServices = () => {
  return services.filter(item => item.showInNavbar)
};

export default services;
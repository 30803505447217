import React from 'react';

/**
 * Render html. 
 * NOTE: Only use contet from admins here, not from users!
 */
export default function HtmlTextRenderer({ children }) {
  return (
    <div dangerouslySetInnerHTML={{ __html: children }} />
  );
}

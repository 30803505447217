import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: screenConfig => screenConfig.mobileSizes.bottom,
    paddingTop: screenConfig => screenConfig.mobileSizes.top,
    [theme.breakpoints.up('md')]: {
      paddingBottom: screenConfig => screenConfig.desktopSizes.bottom,
      paddingTop: screenConfig => screenConfig.desktopSizes.top
    }
  },
  innerContainer: {
    maxWidth: 1440,
    width: screenConfig => screenConfig.desktopSizes.width,
    [theme.breakpoints.down('md')]: {
      width: screenConfig => screenConfig.mobileSizes.width,
    }
  }
}));

export default function ResponsiveMarginContainer({ children, desktopSizes, mobileSizes }) {
  const screenConfig = { desktopSizes, mobileSizes }
  const classes = useStyles(screenConfig);
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {children}
      </div>
    </div>
  );
}

/**
 * Type definitions
 */
ResponsiveMarginContainer.propTypes = {
  desktopSizes: PropTypes.shape(),
  mobileSizes: PropTypes.shape(),
};

/**
 * Default values
 */
ResponsiveMarginContainer.defaultProps = {
  desktopSizes: {
      width: '82%',
      top: 128,
      bottom: 96
    },
  mobileSizes: {
    width: '94%',
    top: 48,
    bottom: 64,
  }
};

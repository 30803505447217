import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  topLink: {
    textDecoration: 'none',
    color: 'black',
    textTransform: 'uppercase',
    '&:hover': {
      fontWeight: '900'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    textTransform: 'uppercase',
    '&:hover': {
      fontWeight: '900'
    }
  },
  menuLink: {
    paddingTop: 24,
  },
  linkContainer: {
    paddingLeft: 32
  }
}));

export default function MobileExpandableNavLink({onClick, topLabel, links }) {
  const classes = useStyles();

  const renderedLinks = (
    <div className={classes.menuLinks}>
      {links.map(link => {
        return (
          <div key={link.label} className={classes.menuLink}>
            <Typography noWrap={true} variant="h4">
              <Link onClick={onClick} className={classes.link} to={link.to}>
                {link.label}
              </Link>
            </Typography>
          </div>
        )
      })}
    </div>)

  return (
    <>
      <Typography className={classes.topLink} variant="h3">
          {topLabel}
      </Typography>
      <div className={classes.linkContainer}>
        {renderedLinks}
      </div>
    </>
  );
}
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import FooterServices from './footerServices';
import FooterMiddle from './footerMiddle';
import FooterFollowUs from './footerFollowUs';

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100vw',
    paddingTop: settings => settings.paddingTop,
    backgroundColor: settings => settings.bgColor,
  },
  first: {
    borderTop: theme.custom.standardBorder,
    [theme.breakpoints.up('sm')]: {
      borderBottom: theme.custom.standardBorder,
      borderTop: theme.custom.standardBorder,
      borderLeft: 0,
      borderRight: 0,
    }
  },
  middle: {
    borderTop: theme.custom.standardBorder,
    [theme.breakpoints.up('sm')]: {
      borderTop: theme.custom.standardBorder,
      borderBottom: theme.custom.standardBorder,
      borderLeft: theme.custom.standardBorder,
      borderRight: theme.custom.standardBorder,
    }
  },
  last: {
    borderTop: theme.custom.standardBorder,
    borderBottom: theme.custom.standardBorder,
    [theme.breakpoints.up('sm')]: {
      borderBottom: theme.custom.standardBorder,
      borderTop: theme.custom.standardBorder,
      borderLeft: 0,
      borderRight: 0,
    }
  },
  copyRight: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 32,
    paddingBottom: 18,
  },
  item: {
    width: '100%',
    paddingTop: 40,
    paddingBottom: 40,
    display:' flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center'
    }
  }
}));

export default function Footer({colorTheme, paddingTop}) {

  const theme = useTheme();
  const settings = {
    bgColor: colorTheme === 'dark' ? theme.palette.primaryYellow.main : theme.palette.primaryYellow.light1,
    paddingTop: paddingTop? paddingTop : 0
  }
  const classes = useStyles(settings);

  return (
    <footer className={classes.footer}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '96%',
          top: 0,
          bottom: 0,
        }}
        mobileSizes={{
          width: '86%',
          top: 16,
          bottom: 64,
        }}>
        <Grid container>
          <Grid item xs={12} sm={4} className={`${classes.first} ${classes.item}`}>
            <FooterServices />
          </Grid>
          <Grid item xs={12} sm={4} className={`${classes.middle} ${classes.item}`}>
            <FooterMiddle />
          </Grid>
          <Grid item xs={12} sm={4} className={`${classes.last} ${classes.item}`}>
            <FooterFollowUs />
          </Grid>
        </Grid>
        <div className={classes.copyRight}>
          <Typography variant="body2">
            © Copyright Dagsverket 2020
          </Typography>
        </div>
      </ResponsiveMarginContainer>
    </footer>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useScrollChecker } from '../../hooks/useScrollChecker'
import DagsverketBlack from '../icons/dagsverketBlack'
import DesktopNavContainer from './desktopNavContainer';
import DesktopNavLink from './desktopNavLink';
import { motion } from "framer-motion"
import DesktopHoverNavLink from './desktopHoverNavLink';
import { Link } from 'gatsby';
import { navbarServices } from './services';

const useStyles = makeStyles(theme => ({
  navbar: { 
    zIndex: 100,
    height: 175,
    width: '100vw',
    backgroundColor: theme.palette.primaryPink.main,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  logoContainer: {
    height: 71,
  },
  innerContainer: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
  }
}));

const desktopVariants = {
  top: { height: null },
  scrolled: { height: 85 },
}

function checkIfDropdownIsSelected(items, url) {
  return items.some(item => { return url.includes(item.to) })
}

export default function DesktopNavbar({currentPath, animated}) {
  const classes = useStyles();
  const isTop = useScrollChecker();
  const navbarSize = animated && isTop? 'large' : 'small'
  const services = navbarServices();

  return (
      <motion.nav className={classes.navbar}
      initial="scrolled"
      animate={animated && isTop ? "top" : "scrolled"}
      variants={desktopVariants}
      >
        <div className={classes.innerContainer}>
          <DesktopNavContainer isSelected={currentPath.includes('blogg')}>
            <DesktopNavLink label="Blogg" to="/blogg/allt/" />
          </DesktopNavContainer>
          <DesktopNavContainer isSelected={checkIfDropdownIsSelected(services, currentPath)}>
            <DesktopHoverNavLink label="Tjänster" links={services}/>
          </DesktopNavContainer>
          <DesktopNavContainer isSelected={currentPath === '/'} fixedWith={false}>
            <Link aria-label="hem" to="/">
              <DagsverketBlack size={navbarSize} />
            </Link>
          </DesktopNavContainer>
          <DesktopNavContainer isSelected={currentPath.includes('om-oss')} >
            <DesktopNavLink label="Om oss" to="/om-oss/" />
          </DesktopNavContainer>
          <DesktopNavContainer isSelected={currentPath.includes('kontakt')} lastItem>
            <DesktopNavLink label="Kontakt" to="/kontakt/" />
          </DesktopNavContainer>
        </div>
      </motion.nav> 
  ); 
    
}
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import {motion} from 'framer-motion'
import DagsverketBlack from '../icons/dagsverketBlack'
import MobileFullscreenMenu from './MobileFullscreenMenu';

const useStyles = makeStyles(theme => ({
  navbar: {
    zIndex: 100,
    height: 67  ,
    width: '100vw',
    backgroundColor: theme.palette.primaryPink.main,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 32,
  },
  logo: {
    flexGrow: 1,
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
}));


const logoSizes = {
  large: { height: 3, width: 300 },
  small: { height: 20, width: 200 },
};


export default function MobileNavbar( {settings} ) {

  const classes = useStyles(settings);
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const showMenu = () => {
    setMenuIsOpen(true)
  }
  
  const hideMenu = () => {
    setMenuIsOpen(false)
  }
  


  return (
    <>
    <nav className={classes.navbar}>
      <div className={classes.logo}>
        <Link aria-label="hem" to="/" onClick={hideMenu}>
          <DagsverketBlack size="small" sizes={logoSizes} />
        </Link>
      </div>
      {!menuIsOpen && 
        <motion.div
          whileHover={{
            scale: 1.1
          }}
          whileTap={{ scale: 0.9 }}
          className={classes.icon} onClick={showMenu}
          >
          <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M27.0608 3.42401H0.060791V0.424011H27.0608V3.42401Z" fill="#090213" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.0608 11.424H0.060791V8.42401H27.0608V11.424Z" fill="#090213" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.0608 19.424H0.060791V16.424H27.0608V19.424Z" fill="#090213" />
          </svg>
        </motion.div>
      }
      {menuIsOpen && 
        <motion.div
          whileHover={{
            scale: 1.1
          }}
          whileTap={{ scale: 0.9 }}
          className={classes.icon} onClick={hideMenu}
          >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.2743 23.6372L0.0610527 2.42399L2.18237 0.302673L23.3956 21.5159L21.2743 23.6372Z" fill="#090213" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.0607403 21.6372L21.2739 0.423998L23.3953 2.54532L2.18206 23.7585L0.0607403 21.6372Z" fill="#090213" />
          </svg>
        </motion.div>
      }
    </nav>
    {menuIsOpen && (
      <MobileFullscreenMenu 
        menuIsOpen={menuIsOpen}
        hideMenu={hideMenu}
      />
    )}
    </>
  )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import { Typography } from '@material-ui/core';
import { motion } from 'framer-motion';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative'
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    textTransform: 'uppercase',
    '&:hover': {
      fontWeight: '900'
    }
  },
  dropdown: {
    zIndex: 100,
    width: 454,
    position: 'absolute',
    left: -28,
  },
  transparent: {
    height: 9,
    width: '100%',
    background: 'transparent'
  },
  menu: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primaryYellow.main,
    overflow: 'hidden',
    border: theme.custom.standardBorder,
  },
  menuLinks: {
    paddingTop: 48,
    paddingLeft: 24,
  },
  menuLink: {
    paddingBottom: 12,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    paddingRight: 10
  }
}));


const variants = {
  rest: {
    height: 0,
    width: 473,
    display: "none",

  },
  hover: {
    height: 380,
    display: "block",
    width: 473,
    transition: {
      type: 'spring', damping: 40, stiffness: 500
    },
  },
}

const arrowVariants = {
  rest: {
    d: "M8.49989 10.6213L0.939227 3.06068L3.06055 0.939357L8.49989 6.3787L13.9392 0.939358L16.0605 3.06068L8.49989 10.6213Z"
  },
  hover: {
    d: "M7.56066 0.939331L15.1213 8.49999L13 10.6213L7.56066 5.18197L2.12132 10.6213L0 8.49999L7.56066 0.939331Z"
  },
}


export default function DesktopHoverNavLink({ label, links}) {
  const classes = useStyles();

  const renderedLinks = (
    <div className={classes.menuLinks}>
      {links.map(link => {
        return (
          <div key={link.label} className={classes.menuLink}>
            <Typography noWrap={true} variant="h4">
              <Link className={classes.link} to={link.to}>
                {link.label}
              </Link>
            </Typography>
          </div>
        )
      })}
    </div>)

  return (
    <motion.div
      initial="rest"
      whileHover="hover"
      animate="type"
      className={classes.container}>
        <div className={classes.textContainer}>
          <div className={classes.text}>
            <Typography style={{textTransform: 'uppercase'}} variant="h5">
              {label}
            </Typography>
          </div>
          <div>
            <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <motion.path
                variants={arrowVariants}
                fillRule="evenodd" clipRule="evenodd" d="M8.49989 10.6213L0.939227 3.06068L3.06055 0.939357L8.49989 6.3787L13.9392 0.939358L16.0605 3.06068L8.49989 10.6213Z" fill="#090213" />
            </svg>
          </div>
        </div>
      <motion.div 
        variants={variants}
        className={classes.dropdown}>
          <div className={classes.transparent}></div>
          <div 
            className={classes.menu}>
              {renderedLinks}
            </div>
      </motion.div>
    </motion.div>
  );
}
import { useState, useEffect } from 'react';

const pixelsFromTop = 30;

export function useScrollChecker() {
  const [isTop, setIsTop] = useState(true);

  const scrollListener = e => {
    const scrolled = document.scrollingElement.scrollTop;
    if (scrolled > pixelsFromTop) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", scrollListener)
    return () => {
      document.removeEventListener("scroll", scrollListener);
    }
  }, []);

  return isTop;
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: {
    paddingBottom: settings => settings.padding,
    textTransform: 'uppercase',
    fontFamily: 'Rubik, helvetica, sans-serif',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: '0px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.6,
      letterSpacing: '0px',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      fontWeight: '900'
    }
  }
}));

export default function FooterList({ listItems, padding }) {
  const settings = {
    padding: padding ? padding : 0,
  }

  const classes = useStyles(settings);
  return (
    <div className={classes.container}>
      {listItems.map(item => {
        return (
          <div key={item.label}>
            <Typography className={classes.text}>
              <Link className={classes.link} to={item.to}>
                {item.label}
              </Link>
            </Typography>
          </div>    
        )
      })}
    </div>
  );
}

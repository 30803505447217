import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FooterList from './FooterList';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },
}));

export default function FooterMiddle() {
  const classes = useStyles();

  const itemList = [
    {
      to: '/om-oss/',
      label: 'Om oss',
    },
    {
      to: '/kontakt/',
      label: 'Kontakt',
    },
    {
      to: '/blogg/allt/',
      label: 'Blogg',
    },
  ]

  return (
    <div className={classes.container}>
      <FooterList padding={24} listItems={itemList} />
    </div>
  );
}

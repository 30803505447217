import React from 'react';
import { motion } from "framer-motion"

const defaultSizes = {
  large: { height: 71, width: 601 },
  small: { height: 31, width: 263 },
};

export default function DagsverketBlack({size, sizes}) {
  
  const variants = sizes ? sizes : defaultSizes

  return (
    <motion.svg
      animate={size}
      initial={size}
      variants={variants}
      width={variants[size].width}
      height={variants[size].height} viewBox="0 0 601 71" fill="none" xmlns="http://www.w3.org/2000/svg"
     >
      <path d="M2.29761 70.0139C1.68491 70.0139 1.13349 69.7838 0.64333 69.3236C0.214443 68.7977 0 68.206 0 67.5486V3.55C0 2.82685 0.214443 2.23518 0.64333 1.775C1.07222 1.24907 1.62364 0.986109 2.29761 0.986109H26.4684C35.5363 0.986109 42.5823 3.31991 47.6064 7.9875C52.6305 12.5894 55.2651 19.2292 55.5102 27.9069C55.5714 29.8134 55.6021 32.3444 55.6021 35.5C55.6021 38.6556 55.5714 41.1537 55.5102 42.9944C54.8975 61.0074 45.3701 70.0139 26.9279 70.0139H2.29761ZM26.4684 55.2222C30.3284 55.2222 33.1774 54.2361 35.0155 52.2639C36.8536 50.2917 37.8339 47.1361 37.9565 42.7972C38.079 40.8907 38.1403 38.4255 38.1403 35.4014C38.1403 32.3773 38.079 29.9449 37.9565 28.1042C37.8339 23.8968 36.7617 20.8069 34.7398 18.8347C32.7792 16.7968 29.8689 15.7778 26.0089 15.7778H17.278V55.2222H26.4684Z" fill="#090213" />
      <path d="M61.0359 70.0139C60.5458 70.0139 60.0862 69.8167 59.6574 69.4222C59.2897 68.962 59.1059 68.469 59.1059 67.9431C59.1059 67.5486 59.1366 67.2528 59.1978 67.0556L80.6115 3.84584C81.2242 1.93935 82.4496 0.986109 84.2877 0.986109H98.6248C100.463 0.986109 101.688 1.93935 102.301 3.84584L123.715 67.0556C123.776 67.2528 123.807 67.5486 123.807 67.9431C123.807 68.469 123.592 68.962 123.163 69.4222C122.796 69.8167 122.367 70.0139 121.877 70.0139H109.929C108.459 70.0139 107.417 69.2907 106.804 67.8444L103.496 58.2792H79.4168L76.1082 67.8444C75.4955 69.2907 74.4539 70.0139 72.9835 70.0139H61.0359ZM83.2767 43.4875H99.6357L91.4562 18.3417L83.2767 43.4875Z" fill="#090213" />
      <path d="M156.348 71C147.464 71 140.51 68.6991 135.486 64.0972C130.461 59.4296 127.796 52.7898 127.49 44.1778C127.429 42.337 127.398 39.3458 127.398 35.2042C127.398 31.0625 127.429 28.0384 127.49 26.1319C127.735 17.8486 130.4 11.4389 135.486 6.90278C140.632 2.30093 147.586 0 156.348 0C162.23 0 167.346 1.01898 171.696 3.05695C176.107 5.09491 179.446 7.72454 181.713 10.9458C184.042 14.1014 185.267 17.3556 185.39 20.7083C185.39 21.3 185.175 21.8259 184.746 22.2861C184.379 22.6806 183.919 22.8778 183.368 22.8778H169.123C168.51 22.8778 168.02 22.7792 167.652 22.5819C167.346 22.319 167.039 21.9574 166.733 21.4972C165.998 19.6565 164.803 18.0787 163.149 16.7639C161.556 15.4491 159.289 14.7917 156.348 14.7917C149.241 14.7917 145.564 18.7361 145.319 26.625C145.258 28.4657 145.227 31.2926 145.227 35.1056C145.227 38.9185 145.258 41.7782 145.319 43.6847C145.564 52.0338 149.302 56.2083 156.532 56.2083C160.085 56.2083 162.904 55.2551 164.987 53.3486C167.07 51.4421 168.112 48.5495 168.112 44.6708V42.8958H159.932C159.258 42.8958 158.676 42.6657 158.186 42.2056C157.757 41.6796 157.543 41.0551 157.543 40.3319V32.6403C157.543 31.9171 157.757 31.3255 158.186 30.8653C158.676 30.3394 159.258 30.0764 159.932 30.0764H183.276C183.95 30.0764 184.501 30.3394 184.93 30.8653C185.42 31.3255 185.665 31.9171 185.665 32.6403V44.0792C185.665 49.6671 184.44 54.4991 181.989 58.575C179.6 62.5852 176.199 65.675 171.788 67.8444C167.376 69.9482 162.23 71 156.348 71Z" fill="#090213" />
      <path d="M220.19 71C214.247 71 209.192 70.0796 205.025 68.2389C200.859 66.3981 197.704 63.9657 195.559 60.9417C193.415 57.9176 192.281 54.6306 192.159 51.0806C192.159 50.4889 192.343 49.9958 192.71 49.6014C193.139 49.2069 193.629 49.0097 194.181 49.0097H206.404C207.139 49.0097 207.721 49.1412 208.15 49.4042C208.64 49.6671 209.161 50.1273 209.713 50.7847C210.509 52.3625 211.734 53.6773 213.389 54.7292C215.104 55.7153 217.371 56.2083 220.19 56.2083C223.498 56.2083 226.041 55.6824 227.818 54.6306C229.595 53.513 230.483 52.0009 230.483 50.0944C230.483 48.7139 230.023 47.5963 229.104 46.7417C228.185 45.8213 226.715 45.0324 224.693 44.375C222.671 43.6519 219.761 42.863 215.962 42.0083C208.548 40.4306 203.004 38.031 199.327 34.8097C195.651 31.5884 193.813 26.9866 193.813 21.0042C193.813 16.9282 194.855 13.3125 196.938 10.1569C199.082 6.93565 202.085 4.4375 205.945 2.6625C209.866 0.887499 214.4 0 219.546 0C224.938 0 229.595 1.01898 233.516 3.05695C237.498 5.02917 240.531 7.52731 242.614 10.5514C244.759 13.5097 245.892 16.4352 246.015 19.3278C246.015 19.9194 245.831 20.4454 245.463 20.9056C245.096 21.3 244.636 21.4972 244.085 21.4972H231.218C229.87 21.4972 228.829 20.9056 228.093 19.7222C227.726 18.3417 226.776 17.1912 225.244 16.2708C223.713 15.2847 221.813 14.7917 219.546 14.7917C216.973 14.7917 214.982 15.2847 213.573 16.2708C212.163 17.2569 211.459 18.7032 211.459 20.6097C211.459 22.5162 212.378 24.0282 214.216 25.1458C216.054 26.1977 219.485 27.2824 224.509 28.4C230.269 29.5176 234.833 30.931 238.203 32.6403C241.634 34.2838 244.146 36.4532 245.739 39.1486C247.393 41.7782 248.22 45.131 248.22 49.2069C248.22 53.6773 247.026 57.556 244.636 60.8431C242.247 64.1301 238.938 66.6611 234.711 68.4361C230.483 70.1454 225.643 71 220.19 71Z" fill="#090213" />
      <path d="M276.712 70.0139C274.874 70.0139 273.679 69.0935 273.128 67.2528L253.46 3.94445C253.399 3.74722 253.369 3.45139 253.369 3.05695C253.369 2.46528 253.552 1.97223 253.92 1.57778C254.288 1.18333 254.747 0.986109 255.299 0.986109H263.478C264.275 0.986109 264.918 1.2162 265.408 1.67639C265.898 2.13657 266.235 2.62963 266.419 3.15556L281.767 53.6444L297.207 3.15556C297.329 2.62963 297.636 2.13657 298.126 1.67639C298.616 1.2162 299.259 0.986109 300.056 0.986109H308.327C308.817 0.986109 309.246 1.18333 309.614 1.57778C310.043 1.97223 310.257 2.46528 310.257 3.05695L310.073 3.94445L290.498 67.2528C289.885 69.0935 288.69 70.0139 286.914 70.0139H276.712Z" fill="#090213" />
      <path d="M322.187 70.0139C321.574 70.0139 321.053 69.7838 320.625 69.3236C320.196 68.8634 319.981 68.3046 319.981 67.6472V3.45139C319.981 2.72824 320.165 2.13657 320.533 1.67639C320.962 1.2162 321.513 0.986109 322.187 0.986109H362.717C363.391 0.986109 363.942 1.2162 364.371 1.67639C364.8 2.13657 365.014 2.72824 365.014 3.45139V10.0583C365.014 10.7815 364.8 11.3732 364.371 11.8333C363.942 12.2278 363.391 12.425 362.717 12.425H332.572V29.6819H360.695C361.369 29.6819 361.92 29.912 362.349 30.3722C362.778 30.8324 362.992 31.4241 362.992 32.1472V38.3597C362.992 39.0829 362.778 39.6745 362.349 40.1347C361.92 40.5292 361.369 40.7264 360.695 40.7264H332.572V58.575H363.452C364.126 58.575 364.677 58.8051 365.106 59.2653C365.535 59.6597 365.75 60.2185 365.75 60.9417V67.6472C365.75 68.3704 365.535 68.962 365.106 69.4222C364.677 69.8167 364.126 70.0139 363.452 70.0139H322.187Z" fill="#090213" />
      <path d="M379.448 70.0139C378.835 70.0139 378.314 69.7838 377.885 69.3236C377.456 68.8634 377.242 68.3046 377.242 67.6472V3.45139C377.242 2.72824 377.426 2.13657 377.793 1.67639C378.222 1.2162 378.774 0.986109 379.448 0.986109H403.251C410.787 0.986109 416.669 2.85972 420.896 6.60695C425.185 10.3542 427.33 15.6792 427.33 22.5819C427.33 27.381 426.257 31.4241 424.113 34.7111C421.969 37.9324 418.966 40.2662 415.106 41.7125L428.433 66.8583C428.616 67.2528 428.708 67.6144 428.708 67.9431C428.708 68.5347 428.494 69.0278 428.065 69.4222C427.697 69.8167 427.268 70.0139 426.778 70.0139H418.323C417.343 70.0139 416.577 69.7838 416.025 69.3236C415.474 68.7977 414.984 68.1403 414.555 67.3514L402.791 43.9806H390.2V67.6472C390.2 68.3046 389.986 68.8634 389.557 69.3236C389.128 69.7838 388.577 70.0139 387.903 70.0139H379.448ZM402.975 32.7389C406.651 32.7389 409.408 31.8843 411.246 30.175C413.085 28.4 414.004 25.8361 414.004 22.4833C414.004 19.1306 413.085 16.5667 411.246 14.7917C409.408 12.9509 406.651 12.0306 402.975 12.0306H390.2V32.7389H402.975Z" fill="#090213" />
      <path d="M441.375 70.0139C440.763 70.0139 440.242 69.7838 439.813 69.3236C439.384 68.8634 439.17 68.3046 439.17 67.6472V3.45139C439.17 2.72824 439.353 2.13657 439.721 1.67639C440.15 1.2162 440.701 0.986109 441.375 0.986109H449.647C450.321 0.986109 450.872 1.2162 451.301 1.67639C451.73 2.13657 451.944 2.72824 451.944 3.45139V26.5264L472.439 2.76111C473.358 1.57778 474.553 0.986109 476.023 0.986109H485.397C485.888 0.986109 486.316 1.18333 486.684 1.57778C487.113 1.97223 487.327 2.46528 487.327 3.05695C487.327 3.51713 487.174 3.91157 486.868 4.24027L461.87 34.0208L488.706 66.6611C488.951 67.1213 489.074 67.5486 489.074 67.9431C489.074 68.5347 488.89 69.0278 488.522 69.4222C488.154 69.8167 487.726 70.0139 487.235 70.0139H477.586C476.299 70.0139 475.38 69.7509 474.828 69.225C474.277 68.6333 473.971 68.3046 473.909 68.2389L451.944 42.2056V67.6472C451.944 68.3046 451.73 68.8634 451.301 69.3236C450.872 69.7838 450.321 70.0139 449.647 70.0139H441.375Z" fill="#090213" />
      <path d="M499.533 70.0139C498.921 70.0139 498.4 69.7838 497.971 69.3236C497.542 68.8634 497.328 68.3046 497.328 67.6472V3.45139C497.328 2.72824 497.511 2.13657 497.879 1.67639C498.308 1.2162 498.859 0.986109 499.533 0.986109H540.063C540.737 0.986109 541.289 1.2162 541.717 1.67639C542.146 2.13657 542.361 2.72824 542.361 3.45139V10.0583C542.361 10.7815 542.146 11.3732 541.717 11.8333C541.289 12.2278 540.737 12.425 540.063 12.425H509.919V29.6819H538.041C538.715 29.6819 539.267 29.912 539.696 30.3722C540.124 30.8324 540.339 31.4241 540.339 32.1472V38.3597C540.339 39.0829 540.124 39.6745 539.696 40.1347C539.267 40.5292 538.715 40.7264 538.041 40.7264H509.919V58.575H540.798C541.472 58.575 542.024 58.8051 542.453 59.2653C542.882 59.6597 543.096 60.2185 543.096 60.9417V67.6472C543.096 68.3704 542.882 68.962 542.453 69.4222C542.024 69.8167 541.472 70.0139 540.798 70.0139H499.533Z" fill="#090213" />
      <path d="M570.947 70.0139C570.335 70.0139 569.814 69.7838 569.385 69.3236C568.956 68.8634 568.742 68.3046 568.742 67.6472V13.3125H551.739C551.127 13.3125 550.606 13.0824 550.177 12.6222C549.748 12.162 549.534 11.5704 549.534 10.8472V3.45139C549.534 2.72824 549.717 2.13657 550.085 1.67639C550.514 1.2162 551.065 0.986109 551.739 0.986109H598.702C599.376 0.986109 599.928 1.2162 600.357 1.67639C600.786 2.13657 601 2.72824 601 3.45139V10.8472C601 11.5704 600.786 12.162 600.357 12.6222C599.928 13.0824 599.376 13.3125 598.702 13.3125H581.792V67.6472C581.792 68.3046 581.578 68.8634 581.149 69.3236C580.781 69.7838 580.26 70.0139 579.586 70.0139H570.947Z" fill="#090213" />
    </motion.svg>
  );
}

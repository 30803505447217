import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import facebookPng from '../../images/dagsverket-facebook-ikon-social_dark.png'
import instagramPng from '../../images/dagsverket-instagram-ikon-social-dark.png'
// import linkedInPng from '../../images/dagsverket-linked-in-ikon-social-dark.png'
import ExternalLink from '../stuff/ExternalLink';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },
  text: {
    textTransform: 'uppercase',
    paddingBottom: 24,
    /* H6 (lighthouse fix) */
    fontFamily: 'Rubik, helvetica, sans-serif',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: '0px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.6,
      letterSpacing: '0px',
    },
  },
  socialIconGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    },
    gridColumnGap: 12,
    gridRowGap: 12,
  },
  icon: {
    margin: 0,
    width: 50,
    height: 50,
  }
}));

export default function FooterFollowUs() {
  const classes = useStyles();

  const facebook = <img className={classes.icon} src={facebookPng} alt="Facebook" />
  const instagram = <img className={classes.icon} src={instagramPng} alt="Instagram" />
  // const linkedIn = <img className={classes.icon} src={linkedInPng} alt="Linked In" />


  return (
    <div className={classes.container}>
      <Typography className={classes.text}>följ oss</Typography>
      <div className={classes.socialIconGrid}>
        <div className={classes.icon}>
          <ExternalLink href="https://www.facebook.com/dagsverket.io/">
            {facebook}
          </ExternalLink>
        </div>
        <div className={classes.icon}>
          <ExternalLink href="https://www.instagram.com/dagsverket.io/">
            {instagram}
          </ExternalLink>
        </div>
        {/* <div className={classes.icon}>
          <ExternalLink href="https://www.facebook.com/dagsverket/">
            {linkedIn}
          </ExternalLink>
        </div> */}
      </div>
    </div>
  );
}

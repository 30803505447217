 import React from 'react';
 import { makeStyles } from '@material-ui/core/styles';
 
 const useStyles = makeStyles(theme => ({
   smDesktop: {
     display: 'none',
     [theme.breakpoints.up('sm')]: {
       display: 'block',
     }
   },
   smMobile: {
     display: 'block',
     [theme.breakpoints.up('sm')]: {
       display: 'none',
     }
   },
   mdDesktop: {
     display: 'none',
     [theme.breakpoints.up('md')]: {
       display: 'block',
     }
   },
   mdMobile: {
     display: 'block',
     [theme.breakpoints.up('md')]: {
       display: 'none',
     }
   },
    lgDesktop: {
     display: 'none',
     [theme.breakpoints.up('lg')]: {
       display: 'block',
     }
   },
   lgMobile: {
     display: 'block',
     [theme.breakpoints.up('lg')]: {
       display: 'none',
     }
   }
 }));

export default function ResponsiveComponentRenderer({ mobileComponent, desktopComponent, size }) {
  const classes = useStyles();

  let component;
  switch(size) {
    case 'sm': {
      component = <>
        <div className={classes.smDesktop}>
          {desktopComponent}
        </div>
        <div className={classes.smMobile}>
          {mobileComponent}
        </div>
      </>;
      break; 
    }
    case 'md': {
      component = <>
        <div className={classes.mdDesktop}>
          {desktopComponent}
        </div>
        <div className={classes.mdMobile}>
          {mobileComponent}
        </div>
      </>;
      break;
    }
    case 'lg': {
      component = <>
        <div className={classes.lgDesktop}>
          {desktopComponent}
        </div>
        <div className={classes.lgMobile}>
          {mobileComponent}
        </div>
      </>;
      break;
    }
    default: {
      component = <>
        <div className={classes.lgDesktop}>
          {desktopComponent}
        </div>
        <div className={classes.lgMobile}>
          {mobileComponent}
        </div>
      </>;
      break;
    }
  }
  
  return component;
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'black',
    textTransform: 'uppercase',
  }
}));

export default function DesktopNavLink({ label, to}) {
  const classes = useStyles();
  return (
    <Typography variant="h5">
      <Link className={classes.link} to={to}>
          {label}
      </Link>
    </Typography>
  );
}

/**
* Type definitions
*/
DesktopNavLink.propTypes = {
  to: PropTypes.string.isRequired,
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'black',
    textTransform: 'uppercase',
    '&:hover': {
      fontWeight: '900'
    }
  }
}));

export default function MobileNavLink({ onClick, label, to}) {
  const classes = useStyles();
  return (
    <Typography variant="h3">
      <Link onClick={onClick} className={classes.link} to={to}>
          {label}
      </Link>
    </Typography>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'black',
  }
}));

const removeTrailingSlash = (str) => {
  const lastChar = str.slice(-1);
  return lastChar === '/' ? str.substring(0, str.length - 1) : str;
}

export default function InternalLink({ children, to, anchorTag}) {
  const classes = useStyles();
  const url = anchorTag ? `${removeTrailingSlash(to)}#${anchorTag}` : to;
  return (
    <Link className={classes.link} to={url}>
      {children}
    </Link>
  );
}

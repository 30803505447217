import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import MobileExpandableNavLink from './MobileExpandableNavLink';
import MobileNavLink from './MobileNavLink';
import { navbarServices } from './services';

const useStyles = makeStyles(theme => ({
  menu: {
    width: '100vw',
    position: 'fixed',
    height: 'calc(100vh - 67px)',
    paddingBottom: 100,
    top: 67,
    left: 0,
    backgroundColor: theme.palette.primaryYellow.main,
    border: theme.custom.standardBorder,
    zIndex: 100,
    overflowY: 'visible',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
  },
  links: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 64,
    paddingLeft: '10%',
  },
  link: {
    paddingTop: 24,
  },
}));

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
}

export default function MobileFullscreenMenu({menuIsOpen, hideMenu}) {
  const classes = useStyles();
  const services = navbarServices();

  //Lock background scroll
  useLockBodyScroll();

  return (
    <motion.div
      initial="closed"
      animate={menuIsOpen ? "open" : "closed"}
      variants={variants}
      className={classes.menu}>
      <div className={classes.links}>
        <MobileExpandableNavLink
          onClick={hideMenu}
          topLabel="Tjänster"
          links={services}
        />
        <div className={classes.link}>
          <MobileNavLink onClick={hideMenu} label="Blogg" to="/blogg/allt/"  />
        </div>
        <div className={classes.link}>
          <MobileNavLink onClick={hideMenu} label="Om oss" to="/om-oss/" />
        </div>
        <div className={classes.link}>
          <MobileNavLink onClick={hideMenu} label="kontakt" to="/kontakt/" />
        </div>
      </div>
    </motion.div>
  );
}